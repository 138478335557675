import React from "react";
import { useInView } from "react-intersection-observer";

export default function About() {
   const [ref1, inView1] = useInView({
      threshold: 0.2
   })
   return (
      <div className="about py-32 px-16 bg-neutral-900" ref={ref1} id="about">
         <div className={`container bg-gradient-to-b transition-all from-pri to-sec rounded-2xl mx-auto ${inView1 && "turnL"}`}>
            <div className={`grid md:grid-cols-2 bg-neutral-900 rounded-2xl p-4 px-8 items-center border-2 border-yellow-300 ${inView1 && "turnR"}`}>
               <div className="wrap">
                  <div className={`heading text-white md:text-6xl text-3xl font-extrabold uppercase py-3`}>
                     <span>ABOUT INjective BNB - $INB</span>
                  </div>
                  <div className="text-wrap text-gray-400">
                     <span>
                        $INB is a community driven project aimed at  introducing the bnb maxis to inj ecosystem through reward mechanism, hence the name injected bnb.
                     </span>
                  </div>
               </div>
               <div className="img-wrap flex justify-center">
                  <img
                     src="/asset/logo.png"
                     alt="Injective BNB"
                     className="w-[300px]"
                  />
               </div>
            </div>
         </div>
      </div>
   );
}
