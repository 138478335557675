import React from "react";

export default function Footer() {
   return (
      <div className="wrao p-4 px-10 bg-sec flex justify-between  text-white font-bold">
         <span>
            Powered by{" "}
            <span className="text-pri">
               Injected <span className="text-yellow-300">BNB</span>
            </span>
         </span>

         {/* Copyright section  */}
         <span>All Copyright reserved.  $INB 2024</span>
      </div>
   );
}
