import React from "react";
import { FaTelegram, FaTwitter } from "react-icons/fa";

export default function Intro() {
   return (
      <div className="bg-wrap intro bg-neutral-900">
         <div className="fancy-border">
            <div className="flex gap-6 justify-around items-center h-screen backdrop-blur-2xl ">
               <div className="wrap p-3">
                  <div className="text-wrap leading-10">
                     <span className="text-5xl md:text-7xl font-extrabold text-neutral-50">
                        {" "}
                        $INB <br /> INJECTED{" "}
                        <span className="text-yellow-300">BNB</span>{" "}
                     </span>{" "}
                     <br />
                     <span className="text-white">
                        Onboarding the great BNB maxis to Injective.
                     </span>
                  </div>
                  <div className="socials my-4  flex ">
                     <div className="flex gap-5 items-center bg-black bg-opacity-25 p-3 rounded-xl">
                        <div className="heading text-md text-white  font-thin">
                           <span>Stay connected</span>
                        </div>

                        <div className="wrap flex gap-1">
                           <a href="https://x.com/injectedbnb" target={"_blank"} className="rounded-full bg-black p-2">
                              <FaTwitter
                                 className="text-2xl text-pri"
                                 size={30}
                              />
                           </a>
                           <a href="https://t.me/injected_Bnb" target={"_blank"} className="rounded-full bg-black p-2">
                              <FaTelegram
                                 className="text-2xl text-yellow-300"
                                 size={30}
                              />
                           </a>
                        </div>
                     </div>
                  </div>
                  <div className="btn-wrap">
                     <a href="/$INB_WHITEPAPER.pdf" target="_blank">
                        <button className=" bg-white rounded-3xl text-black shadow-xl px-4 py-3">
                           Whitepaper
                        </button>
                     </a>
                  </div>
               </div>
               <div className="img-wrap hidden md:block">
                  <img src="/asset/logo.png" alt="home" />
               </div>
            </div>
         </div>
      </div>
   );
}
