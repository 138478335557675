import React from "react";
import { FaWallet, FaGift, FaFire } from "react-icons/fa";
import { useInView } from "react-intersection-observer";

export default function Card() {
   const cardFunc = (icon, value, text) => {
      return { icon, value, text };
   };
   const cardArr = [
      cardFunc(<FaWallet />, "5", "Fee wallet"),
      cardFunc(<FaGift />, "4", "Holders Reward"),
      cardFunc(<FaFire />, "1", "Token Burn"),
   ];

   const [ref1, inView1] = useInView({ threshold: 0.1 });
   const [ref2, inView2] = useInView({ threshold: 0.1 });
   const [ref3, inView3] = useInView({ threshold: 0.1 });

   const refs = [ref1, ref2, ref3];
   const vuews = [inView1, inView2, inView3];

   return (
      <div className="wrap pt-32 tk">
         <div className="heading flex flex-wrap justify-between items-center my-3">
            <span className="text-3xl font-bold uppercase text-pri">
               $FEE distribution
            </span>

            <div className="text-wrap text-gray-200">
               <span>
                  Holders of $INB receives a reward <br /> of 4% in
                  Inj(injective) which is proportional to the amount you hold.{" "}
               </span>
            </div>
         </div>

         <div className="card-wrap mt-10">
            <div className="grid md:grid-cols-3 gap-4">
               {cardArr.map((i, index) => (
                  <div
                     className={`card rounded-2xl p-4 text-center bg-neutral-800 shadow-black shadow-lg hover:border-2 hover:border-pri ${
                        vuews[index] && "fadeInLeft"
                     }`}
                     key={index}
                     ref={refs[index]}
                  >
                     <div className="card-head text-2xl flex justify-center">
                        <span>{i.icon}</span>
                     </div>
                     <div className="card-value text-4xl font-bold my-4 text-pri">
                        <span>{i.value}%</span>
                     </div>
                     <div className="card-text">
                        <span>{i.text}</span>
                     </div>
                  </div>
               ))}
            </div>
         </div>
      </div>
   );
}
