import React from "react";
import About from "../Components/About";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Intro from "../Components/Intro";
import Reward from "../Components/Reward";
import Tokenomics from "../Components/Tokenomics";

export default function Home() {
   return (
      <div className="">
         <Header />
         <Intro />
         <About />
         <Reward />
         <Tokenomics />
         <Footer />
      </div>
   );
}
