import React, { useState, useEffect } from "react";
import { PieChart } from "react-minimal-pie-chart";
import { useInView } from "react-intersection-observer";

export default function Chart({ data }) {
  const [show, setShow] = useState(false);
  const { ref, inView } = useInView({ threshold: 0.5 });

  useEffect(() => {
    if (inView) {
      setShow(true);
    }
  }, [inView]);

  return (
    <div className="wrap md:w-[50%] mx-auto text-white" ref={ref}>
      {show && (
        <PieChart
          data={data}
          lineWidth={25} // Adjust the lineWidth to make it a donut chart
          animate
          animationDuration={500}
          animationEasing="ease-out"
          label={({ dataEntry }) => `${Math.round(dataEntry.percentage)}%`}
          className="text-white"
          labelPosition={86}
          labelStyle={{
            fontSize: "5px",
            color: "#fff",
            fontFamily: "sans-serif",
          }}
        />
      )}
    </div>
  );
}
