import React from "react";
import { useInView } from "react-intersection-observer";

export default function Reward() {
   const [ref1, inView1] = useInView({ threshold: 0.1 });
   const [ref2, inView2] = useInView({ threshold: 0.1 });
   const [ref3, inView3] = useInView({ threshold: 0.1 });

   return (
      <div className="wrap bg-neutral-900 py-32">
         <div className="container mx-auto text-center">
            <div className="grid gap-3 md:grid-cols-3 items-center">
               <div className="img-wrap flex justify-center items-center" ref={ref1}>
                  <img
                     src="/asset/inj.png"
                     alt="Injective"
                     className={`opacity-0 ${inView1 && "fadeInLeft"} w-[200px] md:w-[300px]`}
                  />
               </div>
               <div
                  className={`text-wrap text-white text-xl opacity-0 ${
                     inView2 && "fadeInRight"
                  } `}
                  ref={ref2}
               >
                  <span>
                     Holders of $INB receives a reward of 4% in Inj(injective)
                     which is proportional to the amount you hold.{" "}
                  </span>{" "}
                  <br /> <br />
                  <span>
                     The more $INB you hold the more INJ you receive as rewards.
                     We believe that this initiative will help create an
                     inclusion of both ecosystems.
                  </span>
               </div>
               <div className="img-wrap flex justify-center items-center" ref={ref3}>
                  <img
                     src="/asset/bnb.png"
                     alt="Binance"
                     className={`opacity-0 ${inView3 && "fadeInRight"} w-[200px] md:w-[300px]`}
                  />
               </div>
            </div>
         </div>
      </div>
   );
}
