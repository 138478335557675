import React from "react";
import Chart from "./Chart";
import { FaBox, FaSquare } from "react-icons/fa";
import Card from "./Card";
import { useInView } from "react-intersection-observer";

export default function Tokenomics() {
   const data = [
      { title: "LP", value: 30, color: "#E38627" },
      { title: "Injective Foundation", value: 10, color: "#C13C37" },
      { title: "Bnb Ecosystem", value: 10, color: "#6A2135" },
      { title: "Airdrop", value: 10, color: "#FF8C00" },
      { title: "Initial Burn", value: 10, color: "#008000" },
      { title: "Marketing", value: 10, color: "#4CAF50" },
      { title: "Listing & Collaborations", value: 15, color: "#008B8B" },
      { title: "Team Allocation", value: 5, color: "#000080" },
   ];

   const [ref1, inView1] = useInView({ threshold: 0.1 });

   return (
      <div className="wrap bg-neutral-900 text-white py-32" ref={ref1} id="tokenomics" >
         <div
            className={`container p-3 mx-auto trans opacity-0 ${
               inView1 && "fadeInLeft"
            }`}
         >
            <div className="headin text-6xl font-bold text-center mb-10">
               <span>Tokenomics</span>
            </div>
            <div className="wrap">
            <div className="grid grid-cols-2 container mx-auto rounded-2xl bg-sec p-4 md:w-[300px] text-lg font-bold items-center gap-3 mb-4">
               <span  className="text-white text-right">Name:</span> <span className="text-yellow-300">Injected BNB</span>
               <span  className="text-white text-right">Ticker:</span> <span className="text-yellow-300">$INB</span>
               <span  className="text-white text-right">Supply:</span> <span className="text-yellow-300">10,000,000</span>
            </div>
         </div>
            <div className="grid grid-cols-2 gap-3 items-center tk1 ">
               <div className="chart-wrap flex justify-end">
                  <Chart data={data} show={true} />
               </div>
               <div className="wrap">
                  <ul className="leading-10">
                     {data.map((i, index) => (
                        <li
                           key={index}
                           className="flex gap-2 items-center md:justify-center text-lg leading-10"
                        >
                           <FaSquare color={i.color} /> <span>{i.title}</span>
                        </li>
                     ))}
                  </ul>
               </div>
            </div>

            {/* Card Section */}
            <Card />
         </div>
      </div>
   );
}
